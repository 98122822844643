import React from 'react';
import './header.css';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h1 style={{ fontFamily: 'Courier, monospace' }}>Krystal Zhang</h1>
        <h2 className="text-light" style={{ fontFamily: 'Courier, monospace' }}> I am a Software Developer
          <div className='header__socials'>
            <a href="https://www.linkedin.com/in/krystalzhang612/" target="_blank" className="linkedin"><BsLinkedin size={80} /></a>
            <a href="https://github.com/KrystalZhang612" target="_blank" className="github"><FaGithub size={80} /></a>
          </div>
          <div className='cta'>
            <a href="#portfolio" className='cvbtn'><div className="cvtext" style={{ fontFamily: 'Courier, monospace', fontSize: '19px' }}>View Projects</div></a>
            <a href="#contact" className='cvbtn cvbtn-primary'><div className="letstalktext">Let's Talk!</div></a>
            <br />
          </div>
        </h2>
        <br />
      </div>
    </header>
  );
};

export default Header;
