import React from 'react';
import Header from './components/header/Header';
import Portfolio from './components/portfolio/Portfolio';

const App = () => {
  return (
    <>
      <Header />
      <Portfolio />
      <br />
    </>
  )
}
export default App; 
